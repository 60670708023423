import styled from "styled-components";

import { mobileSplitPoint } from "./style.constants";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  textalign: center;
  width: 100%;
`;

export const Header = styled.header`
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(77, 76, 76);
`;

export const AppLogo = styled.img`
  padding: 50px 50px 30px 50px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 80%;
  }
`;

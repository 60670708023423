import { useState, useEffect } from "react";
import pizzaBarLogo from "../../assets/blackmans-pizza-bar.jpeg";
import burgerBarLogo from "../../assets/blackmans-burger-bar.jpeg";

import {
  Container,
  IntroContainer,
  InfoBlurb,
  ContentContainer,
  InnerContainer,
  ALink,
  CardContainer,
  CardImage,
} from "./home.styles";

export const Home = () => {
  return (
    <Container>
      <IntroContainer>
        <h2>Hey friend!</h2>
        <InfoBlurb>Please chose which venue to order online from.</InfoBlurb>
        <InfoBlurb>Big ❤️ from The Blackman's Crew</InfoBlurb>
      </IntroContainer>
      <ContentContainer>
        <InnerContainer>
          <ALink href="https://blackmans-brewery-torquay.whoods.com.au/">
            <CardContainer>
              <CardImage
                src={pizzaBarLogo}
                alt="Blackman's Brewery And Pizza"
              />
            </CardContainer>
          </ALink>
          <ALink href="https://blackmans-brewery-ocean-grove.whoods.com.au/">
            <CardContainer>
              <CardImage src={burgerBarLogo} alt="Blackman's Burger Bar" />
            </CardContainer>
          </ALink>
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
};

export default Home;

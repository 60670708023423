import styled from "styled-components";

import { mobileSplitPoint } from "../../style.constants";

const BORDER_RADIUS_DESKTOP = "3px";
const BORDER_RADIUS_MOBILE = "30px";

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;

export const IntroContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  textalign: center;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding-bottom: 30px;
  }
`;

export const InfoBlurb = styled.div`
  font-size: 20px;
  /* padding: 15px 15px 30px 15px; */
  /* padding-bottom: 30px; */
  padding: 0px 15px 15px 15px;
  text-align: center;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 18px;
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const ALink = styled.a`
  width: 100%;
  margin-bottom: unset;

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin-bottom: 30px;
  }
`;

export const CardContainer = styled.div`
  border-radius: ${BORDER_RADIUS_DESKTOP};
  padding: 15px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: ${BORDER_RADIUS_MOBILE};
    padding: unset;
  }
`;

export const CardImage = styled.img`
  border-radius: ${BORDER_RADIUS_DESKTOP};
  left: unset;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: ${BORDER_RADIUS_MOBILE};
    position: relative;
    left: -35px;
  }
`;
